import { useEffect, useState } from "react"

import { SettingsData } from "./settingsData"

export const useSettings = () => {
  const [settings, setSettings] = useState(new SettingsData())
  useEffect(() => {
    fetch("settings.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((settingsJson) => {
        setSettings(new SettingsData(settingsJson))
      })
  }, [])

  return settings
}

// look in index.html.  We are attempting to load the /settings.js
// script, which will place dynamic settings in window.settings
export const settings = new SettingsData(window.settings)

export let environment
export let isStaging
export let isProduction
export let isPR
export let isDebug
export let isSandbox
export let PR_NUMBER
export let segmentKey
export let facebookAppId
export let localExposedBackendUrl
export let hellosignConfig = {}
export let plaidPublicKey
export let plaidEnvironment
export let sentryDsn
export let sentryRelease
export let stripePublishableAPIKey
export let marketingSite
export let practitionerSignup
export let refinerProject
export let SPLITIT_URL
export let SPLITIT_NUM_INSTALLMENTS
export let MAPBOX_TOKEN
export let INTERCOM_APP_ID
export let INTERCOM_REQUEST_LAB_TEST_SURVEY_ID
export let INTERCOM_RESULTS_INTERPRETATION_FEEDBACK_ID
export let INTERCOM_FOOD_PLANS_FEEDBACK_ID
export let INTERCOM_BLOOD_DASHBOARDS_FEEDBACK_ID
export let INTERCOM_IOK_INVENTORY_FEEDBACK_ID
export let INTERCOM_ADD_BIOMARKER_SURVEY_ID
export let PRACTICE_BETTER_URL
export let BODYSITE_URL
export let OPTIMAL_DX_URL
export let PRACTICEQ_URL
export let KALIX_URL
export let HEALTHIE_URL
export let MY_HOLISTIC_OFFICE_URL
export let CALENDLY_GROUP_DEMO_URL
export let CERBO_URL
export let ONBOARDING_DEMO_VIDEO_URL

export function buildAuth0Config(baseUrl, apiBaseUrl) {
  if (isProduction) {
    return {
      domain: "rupa-health-prod.us.auth0.com",
      client_id: "zJ104lKTIPGL1pUI5A2z70O80GnjMs8i",
      redirect_uri: "https://labs.rupahealth.com/callback",
      response_type: "token id_token",
      audience: "https://labs.rupahealth.com/api", // This is the API Identifier in Auth0 dashboard
      scope: "email",
    }
  } else if (isStaging || isPR || isSandbox) {
    return {
      domain: "rupa-health-staging.us.auth0.com",
      client_id: "5pgOvOX84V1sOf82iWT1gluZSttYHLiZ",
      redirect_uri: `${baseUrl}/callback`,
      response_type: "token id_token",
      audience: `${apiBaseUrl}/api`, // This is the API Identifier in Auth0 dashboard
      scope: "email",
    }
  } else {
    return {
      domain: "rupa-health-dev.us.auth0.com",
      client_id: "RUbsYRZ2JeERjeMl4iT4t9sUekqt0uOg",
      redirect_uri: "http://localhost:3000/callback",
      response_type: "token id_token",
      audience: "localhost:8000", // This is the API Identifier in Auth0 dashboard
      scope: "email",
    }
  }
}

if (settings.isLoaded()) {
  // We found dynamic configuration.  Load all existing variables from there
  environment = settings.environment
  isStaging = settings.isStaging()
  isProduction = settings.isProduction()
  isPR = settings.isPR()
  isDebug = settings.isDebug()
  isSandbox = settings.isSandbox()
  PR_NUMBER = settings.prNumber
  segmentKey = settings.segmentKey
  facebookAppId = settings.facebookAppId
  localExposedBackendUrl = settings.localExposedBackendUrl
  hellosignConfig = settings.hellosignConfig
  plaidPublicKey = settings.plaidPublicKey
  plaidEnvironment = settings.plaidEnvironment
  sentryDsn = settings.sentryDsn
  sentryRelease = settings.sentryRelease
  stripePublishableAPIKey = settings.stripePublishableApiKey
  marketingSite = settings.marketingSite
  practitionerSignup = settings.practitionerSignup
  refinerProject = settings.refinerProject
  SPLITIT_URL = settings.splitItUrl
  SPLITIT_NUM_INSTALLMENTS = settings.splitItNumInstallments
  MAPBOX_TOKEN = settings.mapboxToken
  INTERCOM_APP_ID = settings.intercomAppId
  INTERCOM_REQUEST_LAB_TEST_SURVEY_ID = settings.intercomRequestLabTestSurveyId
  INTERCOM_RESULTS_INTERPRETATION_FEEDBACK_ID =
    settings.intercomResultsInterpretationFeedbackId
  INTERCOM_FOOD_PLANS_FEEDBACK_ID = settings.intercomFoodPLansFeedbackId
  INTERCOM_BLOOD_DASHBOARDS_FEEDBACK_ID =
    settings.intercomBloodDashboardsFeedbackId
  INTERCOM_IOK_INVENTORY_FEEDBACK_ID = settings.intercomAddBiomarkerSurveyId
  INTERCOM_ADD_BIOMARKER_SURVEY_ID = settings.intercomAddBiomarkerSurveyId
  PRACTICE_BETTER_URL = settings.practiceBetterUrl
  BODYSITE_URL = settings.bodysiteUrl
  OPTIMAL_DX_URL = settings.optimalDxUrl
  PRACTICEQ_URL = settings.practiceQUrl
  KALIX_URL = settings.kalixUrl
  HEALTHIE_URL = settings.healthieUrl
  MY_HOLISTIC_OFFICE_URL = settings.myHolisticOfficeUrl
  CALENDLY_GROUP_DEMO_URL = settings.calendlyGroupDemoUrl
  CERBO_URL = settings.cerboUrl
  ONBOARDING_DEMO_VIDEO_URL = settings.onboardingDemoVideoUrl
} else {
  // No dynamic config.  Populate settings with existing code approach
  environment = process.env.REACT_APP_ENVIRONMENT || "Dev"
  isStaging = environment === "Staging"
  isProduction = environment === "Production"
  isPR = environment === "PR"
  isDebug = environment === "Dev"
  isSandbox = environment === "Sandbox"

  PR_NUMBER = process.env.REACT_APP_PR_NUMBER

  localExposedBackendUrl = "https://rupa-dev.ngrok.io"

  // Hellosign config options: https://app.hellosign.com/api/embeddedSigningWalkthrough

  if (isProduction) {
    segmentKey = "9bIufEyVV9KEkdjFvpzoqc2sQxddDcP5"
    facebookAppId = "237638801459831"
    hellosignConfig = {
      clientId: "22539854ba3fd9a02d3cc311e80f9258",
    }
  } else if (isStaging || isPR || isSandbox) {
    segmentKey = "Y9XzO1hmqvrCe0vVXiQD1tgsmulxIdPB"
    facebookAppId = "247851797340117"

    // Hellosign
    if (isPR) {
      // Temporary workaround:
      // PR env needs the same Hellosign config as dev,
      // since our E2E tests use the dev profile
      hellosignConfig = {
        clientId: "22539854ba3fd9a02d3cc311e80f9258",
        skipDomainVerification: true,
        debug: true,
      }
    } else if (isSandbox) {
      // Sandbox config
      hellosignConfig = {
        clientId: "22539854ba3fd9a02d3cc311e80f9258",
        skipDomainVerification: true,
        debug: true,
      }
    } else {
      // Staging config
      hellosignConfig = {
        clientId: "22539854ba3fd9a02d3cc311e80f9258",
        skipDomainVerification: true,
        debug: true,
      }
    }
  } else {
    segmentKey = "LpHHZlTgsu6HsUU2gACyGZ2K8xvPP0md"
    facebookAppId = "182671577172681"
    hellosignConfig = {
      clientId: "22539854ba3fd9a02d3cc311e80f9258",
      skipDomainVerification: true,
      debug: true,
    }
  }

  plaidPublicKey = "fd124e633570a0516144cff649a40d"

  if (isProduction) {
    plaidEnvironment = "production"
  } else {
    plaidEnvironment = "sandbox"
  }

  sentryDsn =
    "https://40fc71f4210146729d687d6c496ec60f@o1248170.ingest.sentry.io/2283198"
  sentryRelease = process.env.REACT_APP_SENTRY_RELEASE

  stripePublishableAPIKey = isProduction
    ? "pk_live_Bk1qreAMOpYmX33e6VkGQtXy"
    : "pk_test_WncJE0lboJk5vM7Xvu0Pf6DP"

  if (isProduction) {
    marketingSite = "https://rupahealth.com"
  } else {
    marketingSite = "https://rupa-health-staging.webflow.io"
  }

  if (isSandbox) {
    practitionerSignup = "https://beta-docs.rupahealth.com/settings"
  } else {
    practitionerSignup = marketingSite + "/sign-up"
  }

  if (isProduction) {
    refinerProject = "658a03a0-643a-11eb-a8d1-0d034b42228b"
  } else if (isStaging || isPR || isSandbox) {
    refinerProject = "08081b40-6676-11eb-8bc0-e1ed0a21de4e"
  } else {
    refinerProject = "ddba3b50-6675-11eb-a9b5-edc404141c74"
  }

  // SplitIt
  SPLITIT_NUM_INSTALLMENTS = 3

  if (process.env.REACT_APP_SPLITIT_URL) {
    SPLITIT_URL = process.env.REACT_APP_SPLITIT_URL
  } else if (isProduction) {
    SPLITIT_URL =
      "https://flexfields.production.splitit.com/v2.0/splitit.flex-fields.sdk.js"
  } else {
    SPLITIT_URL =
      "https://flexfields.sandbox.splitit.com/v2.0/splitit.flex-fields.sdk.js"
  }

  MAPBOX_TOKEN =
    "pk.eyJ1IjoicnVwYWhlYWx0aCIsImEiOiJja3cweTNqcWsxcjM1Mm50MzdqcXFrNGpjIn0.XHBexPKY6_TJDmKP4pxuPQ"

  // Intercom=

  if (isProduction) {
    INTERCOM_APP_ID = "vt5gv01x"
    INTERCOM_REQUEST_LAB_TEST_SURVEY_ID = 27291971
    INTERCOM_RESULTS_INTERPRETATION_FEEDBACK_ID = 29688364
    INTERCOM_FOOD_PLANS_FEEDBACK_ID = 33589089
    INTERCOM_BLOOD_DASHBOARDS_FEEDBACK_ID = 36295770
    INTERCOM_IOK_INVENTORY_FEEDBACK_ID = 38855100
    INTERCOM_ADD_BIOMARKER_SURVEY_ID = 38116513
  } else if (isStaging || isPR || isSandbox) {
    INTERCOM_APP_ID = "le39exzz"
    INTERCOM_REQUEST_LAB_TEST_SURVEY_ID = 27353531
    INTERCOM_RESULTS_INTERPRETATION_FEEDBACK_ID = 27353531
    INTERCOM_FOOD_PLANS_FEEDBACK_ID = 27353531
    INTERCOM_IOK_INVENTORY_FEEDBACK_ID = 27353531
    INTERCOM_ADD_BIOMARKER_SURVEY_ID = 27353531
  } else {
    INTERCOM_APP_ID = "le39exzz"
    INTERCOM_REQUEST_LAB_TEST_SURVEY_ID = 27353531
    INTERCOM_RESULTS_INTERPRETATION_FEEDBACK_ID = 27353531
    INTERCOM_FOOD_PLANS_FEEDBACK_ID = 27353531
    INTERCOM_IOK_INVENTORY_FEEDBACK_ID = 27353531
    INTERCOM_ADD_BIOMARKER_SURVEY_ID = 27353531
  }

  // PracticeBetter

  if (isProduction) {
    PRACTICE_BETTER_URL =
      "https://my.practicebetter.io/#/p/profile/integrations?category=labs"
  } else {
    PRACTICE_BETTER_URL =
      "https://staging.betterclientmanagement.com/#/p/profile/integrations?category=labs"
  }

  // BodySite

  if (isProduction) {
    BODYSITE_URL = "https://www.mybodysite.com/rupa_health_auth_redirect"
  } else {
    BODYSITE_URL = "http://www.mybodysite.com:88/rupa_health_auth_redirect"
  }

  // Optimal DX

  if (isProduction) {
    OPTIMAL_DX_URL = "https://my.optimaldx.com/account/integrations/labs/rupa"
  } else {
    OPTIMAL_DX_URL =
      "https://staging.optimaldx.com/account/integrations/labs/rupa"
  }

  // practiceQ / intakeQ
  if (isProduction) {
    PRACTICEQ_URL = "https://intakeq.com/#/rupahealth/settings"
  } else {
    PRACTICEQ_URL = "https://intakeq.dev/#/rupahealth/settings"
  }

  // Kalix Health
  if (isProduction) {
    KALIX_URL = "https://app.kalixhealth.com/clinicians/me/integrations"
  }

  if (isProduction) {
    HEALTHIE_URL = "https://secure.gethealthie.com/lab_orders"
  } else {
    HEALTHIE_URL = "https://securestaging.gethealthie.com/lab_orders"
  }

  // My Holistic Office

  if (isProduction) {
    MY_HOLISTIC_OFFICE_URL = "https://app.myholisticoffice.com/signin"
  } else {
    MY_HOLISTIC_OFFICE_URL = "https://app.myholisticoffice.com/signin"
  }

  // Cerbo
  CERBO_URL =
    "https://help.cer.bo/support/solutions/articles/8000110422-rupa-health-integration"

  // Calendly Group Demo

  if (isProduction) {
    CALENDLY_GROUP_DEMO_URL =
      "https://calendly.com/rupalabs/demo?hide_event_type_details=1&hide_gdpr_banner=1"
  } else {
    CALENDLY_GROUP_DEMO_URL =
      "https://calendly.com/jordan-stern/45-min-1-1?hide_event_type_details=1&hide_gdpr_banner=1"
  }

  // playsinline=0 forces mobile to go full screen when user clicks play
  ONBOARDING_DEMO_VIDEO_URL =
    "https://player.vimeo.com/video/758895724?h=1d9b9d0610&playsinline=0"
}
